import * as React from "react"
import AboutView from "../views/AboutView"
import { usePages } from "../hooks/usePages"
import { useMembers } from "../hooks/useMembers"
import { useItems } from "../hooks/useItems"

const About = () => {
  const info = usePages("About Spanish")
  const members = useMembers("es")
  const buttonText = useItems("Button Spanish").content[0].text

  return <AboutView info={info} members={members} buttonText={buttonText} />
}

export default About
